.main-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  .order-container {
  height: 400px;
  transition:0;
}
}
.microsite-content{
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .name{
    input{
      max-width: 75%;
    }
  }
  .input-flex{
    input{
      max-width: 100%;
    }
  }
}

.order-container {
  position: relative;
  max-height: 0;
  overflow: "hidden";
  // transition: max-height 0.9s ease-in-out;
  [data-rbd-droppable-id='droppable'] {
    width: 100vw !important;
    margin:0 auto;
  } div[role=button]{
    min-width: 125px;
    height: 124px;
    .showroom-card-container{
      height: 100%;
      gap: 15px;
      padding-top: 25px;
    }
  }
}

.visible-show {
  // max-height: 300px;
  // transition: max-height 0.9s ease-in-out;
}

.text-container-button {
  position:fixed;
  visibility: visible;
  opacity: 1;
  top: 15px;
  right: 90px;
  z-index: 999;
  opacity: 0.7;
  cursor: pointer;
  .reorderTooltip{
    visibility: hidden;
    margin-top: 5px;
    position: absolute;
    right: -50px;
    color: rgba(255, 255, 255, 0.443);
    white-space: nowrap;
    font-size: 14px;
  }
  svg{
    path{
      fill: #ec7600;
    }
  }
  &:hover{
    opacity: 1;
    .reorderTooltip{
      visibility: visible;
    }
  }
  // max-height: 30px;
  // transition: max-height 0.6s ease-in-out;
}

.hide-text {
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  // transition: max-height 0.6s ease-in-out;
}

.finishmicrosite {
  // position: absolute !important;
  // bottom: 2rem;
  // left: 5rem;
  // padding: 5px !important;
  background-color: rgba(49, 49, 49, 0.753) !important;
  border-radius: 30px !important;
}

.edit-microsite {
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;

  svg {
    margin: 0 3px;
    width: 25px;
    height: 25px;
    cursor: pointer;
&:hover{
    g,path{
      fill: white;
    }
}
    g,
    path {
      fill: rgba(255, 255, 255, 0.37);
    }
  }
}

.slider-wrapper {
  width: 100vw;
}

.slider-container {
  width: 100vw;
  height: 100%;
  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
}

.card-room {
  // min-width: 60px;
  height: 25px;
  transition: transform 0.9s ease-in-out;
  background-color: transparent;
  cursor: pointer;
  color: #9d9d9d;
  border: none;
}

.room-container {
  margin-top: 1vh;
  margin-bottom: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  height: 5vh;
  gap: 30px;
  visibility: visible;
  margin-top: 0px;
  svg{
    g{
      fill: transparent;
    }
  }
}

.display-none {
  visibility: hidden;
}

.left-header {
  padding: 0;
  margin: 0;
  padding-left: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    text-align: left;
    font: normal normal normal 23px/27px Raleway;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .orange-p {
    text-align: left;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: #ec7600;
  }
}

.left-header-mw {
  padding: 0;
  margin: 0;
  padding-left: 50px;
  width: 33.3%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    text-align: left;
    font: normal normal normal 23px/27px Raleway;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .orange-p {
    text-align: left;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: #ec7600;
  }
}

.right-header {
  padding: 0;
  margin: 0;
  padding-right: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#item-1:checked~.room-container #card-header-1,
#item-2:checked~.room-container #card-header-2,
#item-3:checked~.room-container #card-header-3,
#item-4:checked~.room-container #card-header-4,
#item-5:checked~.room-container #card-header-5,
#item-6:checked~.room-container #card-header-6,
#item-7:checked~.room-container #card-header-7 {
  color: #ffffff;
}

.show-room-container {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hide-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;

  .show-room-item {
    display: contents;
  }
}

.show-room-item {
  position: absolute;
  // display: flex;
  top: 0rem;
  width: 90vw;
  height: 86vh;
  justify-content: center;
  align-items: center;
  background-color: #202020;
  padding: 25px 40px;
  border: none;
  box-shadow: 0px 3px 20px #0a0a0a;
  border-radius: 20px;
  transition: transform 0.9s ease-in-out;
  cursor: pointer;
  overflow-y: hidden;

  .shared-template {
    padding-top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .item-center-1{
    margin-top: -2rem !important;
  }

  .previewtemplate {
    width: 83% !important;
    padding-left: 1.3rem !important;
    .single-template-12, .single-template-13, .single-template-14{
      .content{
       margin-bottom: 40px !important;
      }
   } 
   &.single-template-12{
    width: 70% !important;
   }
  &.single-template-13{
   width: 75% !important;
   .template-single-card{
       display: flex;
       align-items: flex-start;
       height: 100%;
       .image{
           width: 80%;
       }
       .textcontainer{
           width: 25%;
           display: flex;
           flex-direction: column;
           justify-content: center;
           align-items: center;
           padding-left: 2rem;
           height: 100%;
           p{
               word-break: break-word;
           }
       }
   }
  }
  &.single-template-14{
   width: 70% !important;
   .content{
       width: 90%;
       margin: 0 auto;
   }
  }
  &.single-template-15{
   width: 55% !important;
   .cards{
       overflow: visible;
   }
   .mainplaybtn{
       margin: 25% auto !important;
   }
    .order-container {
    height: 400px;
    transition:0;
  }
  }
  }
}

  /* scrollbar */
  .show-room-item::-webkit-scrollbar {
    width: 0px;
  }

#item-1:checked~.show-room-container #card-2,
#item-2:checked~.show-room-container #card-3,
#item-3:checked~.show-room-container #card-4,
#item-4:checked~.show-room-container #card-5,
#item-5:checked~.show-room-container #card-6,
#item-6:checked~.show-room-container #card-7 {
  transform: translatex(100%);
}

#item-1:checked~.show-room-container #card-3,
#item-2:checked~.show-room-container #card-4,
#item-3:checked~.show-room-container #card-5,
#item-4:checked~.show-room-container #card-6,
#item-5:checked~.show-room-container #card-7 {
  transform: translatex(200%);
}

#item-1:checked~.show-room-container #card-4,
#item-2:checked~.show-room-container #card-5,
#item-3:checked~.show-room-container #card-6,
#item-4:checked~.show-room-container #card-7 {
  transform: translatex(300%);
}

#item-1:checked~.show-room-container #card-5,
#item-2:checked~.show-room-container #card-6,
#item-3:checked~.show-room-container #card-7 {
  transform: translatex(400%);
}

#item-1:checked~.show-room-container #card-6,
#item-2:checked~.show-room-container #card-7 {
  transform: translatex(500%);
}

#item-1:checked~.show-room-container #card-7 {
  transform: translatex(600%);
}

#item-1:checked~.show-room-container #card-1,
#item-2:checked~.show-room-container #card-2,
#item-3:checked~.show-room-container #card-3,
#item-4:checked~.show-room-container #card-4,
#item-5:checked~.show-room-container #card-5,
#item-6:checked~.show-room-container #card-6,
#item-7:checked~.show-room-container #card-7 {
  transform: translatex(0) scale(1);
}

#item-2:checked~.show-room-container #card-1,
#item-3:checked~.show-room-container #card-2,
#item-4:checked~.show-room-container #card-3,
#item-5:checked~.show-room-container #card-4,
#item-6:checked~.show-room-container #card-5,
#item-7:checked~.show-room-container #card-6 {
  transform: translatex(-95%);
}

#item-3:checked~.show-room-container #card-1,
#item-4:checked~.show-room-container #card-2,
#item-5:checked~.show-room-container #card-3,
#item-6:checked~.show-room-container #card-4,
#item-7:checked~.show-room-container #card-5 {
  transform: translatex(-120%);
}

#item-4:checked~.show-room-container #card-1,
#item-5:checked~.show-room-container #card-2,
#item-6:checked~.show-room-container #card-3,
#item-7:checked~.show-room-container #card-4 {
  transform: translatex(-180%);
}

#item-5:checked~.show-room-container #card-1,
#item-6:checked~.show-room-container #card-2,
#item-7:checked~.show-room-container #card-3 {
  transform: translatex(-240%);
}

#item-6:checked~.show-room-container #card-1,
#item-7:checked~.show-room-container #card-2 {
  transform: translatex(-300%);
}

#item-7:checked~.show-room-container #card-1 {
  transform: translatex(-360%);
}



.svg {
  width: 30px;
  height: 30px;
  cursor: pointer;

  g,
  path {
    fill: rgba(230, 230, 230, 0.459);
  }

  &:hover {

    g,
    path {
      fill: white;
    }
  }
}

.selected-cover-page {
  width: 90%;
  background-image: url("https://master-wizr-uploads.s3.amazonaws.com/studio/images.png");
  background-size: cover;
  height: 46px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-cover-page-container {
  width: 100%;
  background-color: transparent;
  display: flex;
  height: 48px;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .title-container {
    width: 33.3%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;


    p {
      font: normal normal bold 20px/24px Raleway;
      margin: 0;
      padding: 0;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
    }

  }
}

.bright-mode {
  .show-room-item {
    background-color: white;
  }
  #item-1:checked ~ .room-container #card-header-1, #item-2:checked ~ .room-container #card-header-2, #item-3:checked ~ .room-container #card-header-3, #item-4:checked ~ .room-container #card-header-4, #item-5:checked ~ .room-container #card-header-5, #item-6:checked ~ .room-container #card-header-6, #item-7:checked ~ .room-container #card-header-7{
    color:#212529;
  }
  .card-room{
    color: black;
    svg{
      path{
        stroke: black;
      }
    }
  }
  .toggle-microsite-edit{
    line{
      stroke:black;
      opacity: 0.5;
    }
    svg{
      g,path{
        fill: black;
        opacity: 0.5;
      }
    }
  }
  .reorder-micro-btn {
    svg {
      path {
        fill: black;
        opacity: 0.8;
      }
    }
  }
}

.center-content {
  input{
    background-color: #1f1f1f7a !important;
  }
  .tags {
    align-items: flex-start !important;
  }

  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  .title {
    color: #8D8D8D;
  }

  .name {
    display: inline-flex;
    gap: 20px;
    width: 100%;

    p {
      width: 60px;
    }

    button {
      background: #0C0C0C;
      width: 75px;
      height: 30px;
      text-align: center;
      color: #CCCCCC;
      border-radius: 5px;
    }
  }

  input {
    width: 82%;
  }
}

.buttons {
  display: flex;
  gap: 5px;
  width: 100%;
  // justify-content: center;
}

.finish {
  padding: 8px 20px;
  background: #FC9621;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  width: 100px;
  display: inline-block;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;

  &.previewbtn {
    background-color: rgb(48, 48, 48);
    color: lightgray;
    margin-right: 10px;

    &:before {
      background-color: rgb(39, 39, 39);
      color: white;
    }
  }
  &.finishmicrosite {
    width: 150px;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
}
.green-mode, .blue-mode, .purple-mode{
  .reorder-micro-btn{
    svg{
      opacity: 0.8;
      &:hover{
        opacity: 1;
      }
    }
  }
}

.bright-mode, .green-mode, .blue-mode, .purple-mode{
  .microsite-full-container{
    &.blurMicrosite{
      &::after{
        background-image: none;
      }
    }
  }
  .card-room{
    svg{
      // opacity: 1;
      path{
        fill: white;
      }
    }
  }
}
.purple-mode {
  .finish {
    background-color: rgba(43, 71, 119, 0.9843137255);

    &::before {
      background-color: rgba(43, 71, 119, 0.9843137255);
    }

    &.previewbtn {
      background-color: #3a5d9e;

      &::before {
        background-color: #507dd0;
      }
    }
  }
}

.green-mode {
  .finish {
    background-color: #004938;

    &::before {
      background-color: #004938;
    }

    &.previewbtn {
      background-color: #02604c;

      &::before {
        background-color: #078369;
      }
    }
  }
}

.blue-mode {
  .finish {
    background-color: rgba(5, 94, 142, 0.8078431373);

    &::before {
      background-color: rgba(5, 94, 142, 0.8078431373);
    }

    &.previewbtn {
      background-color: #0375b2;

      &::before {
        background-color: #0175B2;
      }
    }
  }
}
.toggle-microsite-edit{
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 20px;
  z-index: 99999;
  line{
    stroke:rgba(255, 255, 255, 0.309) ;
  }
  svg{
  width: 22px;
  height: 22px;
    g,path{
      fill: rgba(255, 255, 255, 0.309);
    }
  }
}
.reorder-micro-btn{
  svg{
    opacity: 0.5;
    &:hover{
      opacity: 1;
    }
  }
}


@media screen and (max-width: 1024px) {
  .show-room-item {
    height: 47vh;
  }
}
@media screen and (min-width: 1040px) {
  .order-container {
    [data-rbd-droppable-id='droppable'] {
      width: 90vw !important;
    } div[role=button]{
      min-width: 145px;
      height: 140px;
    }
  }
}
@media screen and (min-width: 1800px) {
  .order-container {
    width: 100vw;
   div[role=button]{
      min-width: 155px;
      height: 150px;
    }
  }
}

@media screen and (min-width: 2000px) {
  .room-container {
    gap: 20px;
    margin-bottom: 3rem;
  }

  .card-room {
    font-size: 22px;
  }

  .finish{
    &.finishmicrosite {
      padding: 10px 20px;
      width: 180px;
      font-size: 18px;
  }
  }
}
@media screen and (min-width: 2560px) {
  .card-room svg {
    zoom: 1.4;
  }
  .edit-microsite{
    svg{
      width: 35px;
      height: 35px;
    }
  }
  .toggle-microsite-edit{
    svg{
      width: 33px;
      height: 33px;
    }
  }
  .reorder-micro-btn{
    svg{
      margin-top: 4px;
    }
  }
}
@media screen and (min-width: 3000px) {
  .room-container {
    gap: 25px;
  }

  .card-room {
    font-size: 35px;
  }
  .finish{
    &.finishmicrosite {
      padding: 15px 20px;
      width: 350px;
      font-size: 30px;
    }
  }
  .card-room svg {
    zoom: 2;
  }
  .edit-microsite{
    svg{
      width: 50px;
      height: 50px;
    }
  }
  .toggle-microsite-edit{
    width: 50px;
    svg{
      width: 50px;
      height: 50px;
    }
  }
}

@media screen and (min-width: 4000px) {
  .room-container {
    gap: 35px;
  }

  .card-room {
    font-size: 45px;
    svg{
      zoom: 2.5;
    }
  }
  .finish{
    &.finishmicrosite {
      padding: 20px;
      width: 380px;
      font-size: 42px;
    }
  }
  .edit-microsite{
    svg{
      width: 60px;
      height: 60px;
      gap: 10px;
    }
  }
}
.order-container-slider{
  width: 100vw;
  height: 90vh;
  background-color: rgba(0, 0, 0, 0.917);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  visibility: hidden;
  .showroom-card-container{
    aspect-ratio: 16/9;
  }
}
.visible-show-slider{
  visibility: visible;
  height: 100vh!important;
}

@media (max-height: 835px){
  .order-container-slider{
    height: 87vh;
  }
  .show-room-item{
    height: 84vh;
  }
}
@media (max-height: 768px) and (max-width: 1550px){
  .show-room-item{
    zoom: 0.9;
    height: 94vh;
  }
}

@media (max-height: 650px) {
  .text-container-button {
    right: 70px;
    top: 12px;
    .reorderTooltip{
      font-size: 12px;
    }
  }
}

@media (max-height: 600px){
  .show-room-item{
    zoom: 0.85;
    height: 98vh;
  }
  .order-container-slider {
    height: 82vh;
  }
  .card-room{
    font-size: 14px;
  }
}
@media (max-height: 955px) and (max-width: 1280px){
  .order-container-slider{
      .reorder-container{
          top: 14%;
      }
  }
}
@media (max-height: 720px) and (max-width: 1280px){
  .order-container-slider{
      .reorder-container{
          top: 12%;
      }
  }
}
@media (max-height: 560px) and (max-width: 1280px){
  .order-container-slider{
      .reorder-container{
          top: 10%;
          zoom: 0.8;
          .reorder-text{
            margin-top: -12px;
          }
      }
  }
}
.backdrop-blur{
  width: 100%;
  height: 100%;
  backdrop-filter: blur(50px);
}
.microsite-full-container{
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  &.blurMicrosite{
    .show-room-item{
      backdrop-filter: blur(50px);
      background: rgba(82, 82, 82, 0.271) !important;
    }
    &::after{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background-image: linear-gradient(to bottom, rgb(23, 23, 23), rgba(0, 0, 0, 0.574),rgba(0, 0, 0, 0));
      content: '';
      z-index: -1;
    }
  }
}
.save-bg{
  font-size:15px;
  position: fixed;
  bottom: 1rem;
  padding: 8px 25px;
  border-radius: 30px;
  background-color: #0303038a;
  backdrop-filter: blur(20px);
  color: white;
  left: 2rem;
  z-index: 9;
}