@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');


*{
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
  }
  .studiocustomizer{
    .mainplaybtn{
      transform: scale(0.7) !important;
      margin-top: 18.2% !important;
      margin-left: 37.4% !important;
    }
  }
  .magazine{
    min-height: 100vh;
  }
  .bright-mode{
    .image-container{
        background-color: #ededed;
        border-radius: 10px;
    }
    .templates-banner{
        svg{
            g,path{
                fill: rgba(0, 0, 0, 0.816) !important;
            }
        }
    }
    .magazine{
      background-color: #d1cfcf;
      input[placeholder=Search]{
        color: white!important;
        &::placeholder{
          color: white!important;
        }
      }
      h1{
        color: white;
      }
      .select-templates{
        background-color: #d1cfcf;
      }
      .designtool-template, .popupContent, .templateRowContainer{
        background-color: #d1cfcf!important;
      }
      .templateRowContainer{
        p{
          color: black!important;;
        }
      }
      .tabsNav{
        border-bottom: 1px solid rgb(22 22 22 / 70%)!important;
        li{
          color: black!important;
          border-bottom: 1px solid black!important;

        }
      }
      .popupContent{
        background-color: #d1cfcf!important;
        p{
          color: rgb(0 0 0);
        }
        input[type=checkbox]{
          display: none;
        }
        label{
          color: white!important;
        }
      }
      .popupTitle{
        p{
          color: white;
        }
      }
      .overlayActionButtons button{
        background-color: black;
      }
      .popupButtons{
        button:nth-child(2) {
          color: black;
        }
      }
      .deletePopupContainer{
        div:nth-child(1) {
          background-color: #d1cfcf;
        }
        h4{
          color: black;
        }
      }
      .paginationmContainer{
        ul{
          opacity: 0.8;
          li{
            color: black;
          }
        }
      }
    }
}
#tox-icon-text-color__color{
  fill: none;
}
.color-purple, .color-gray, .color-lightgray, .color-green, .color-blue, .color-teal, .color-navy{
  .magazine [id^=description].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before, .magazine [id^=description] p{
    color:#191919!important;
  }
}
.bg-frame-7, .bg-frame-8{
  .magazine [id^=main-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before, .magazine [id^=main-heading] p,
  .magazine [id^=main-sub-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before, .magazine [id^=main-sub-heading] p{
    color: black;
  }
  .magazine [id^=description].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before, .magazine [id^=description] p{
    color: #636363;
  }
}
.bg-frame-1, .bg-frame-2, .bg-frame-3, .bg-frame-4, .bg-frame-5, .bg-frame-6{
  .magazine [id^=description].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before, .magazine [id^=description] p{
    color: #a0a0a0;
  }
}
.main-shared-text .mce-content-body[data-mce-placeholder="Add title here"]:not(.mce-visualblocks)::before{
  font-size: 1.7em;
}
.main-shared-text .mce-content-body[data-mce-placeholder="Add subheading here"]:not(.mce-visualblocks)::before{
  font-size: 16px;
  line-height: 1.3rem;
}
.main-shared-text .mce-content-body[data-mce-placeholder="Add description here"]:not(.mce-visualblocks)::before{
  font-size: 15px;
  line-height: 1.3rem;
}
.main-shared-text .mce-content-body[data-mce-placeholder="Add subheading here"]:not(.mce-visualblocks)::before,
.main-shared-text .mce-content-body[data-mce-placeholder="Add description here"]:not(.mce-visualblocks)::before,
.main-shared-text .mce-content-body[data-mce-placeholder="Add title here"]:not(.mce-visualblocks)::before{
  font-family: "Raleway", sans-serif;
}
#file-upload{
  display: none;
}
.shareframecontainer{
  .react-datepicker__input-container{

    input{
      background-color: #5050504f;
    }
  }
}

div[title="Text color"]{
  //  background-color: red !important;
  display: flex;
  align-items: center;
  .tox-split-button__chevron{
    padding-top: 12px !important;
  }
   svg{
    g{
      fill: white !important;
    }
   }
  }
  .eye-preview{
    position: fixed;
    right: 2rem;
    z-index: 9999999;
    top: 1rem;
  
    svg{
      opacity: 0.5;
      width: 23px;
      height: 23px;
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
      line{
        stroke: rgba(255, 255, 255, 0.724);
      }
      g,path{
        fill: rgba(255, 255, 255, 0.753) !important;
      }
    }
  }
  
  .tox-menu{
    &.tox-swatches-menu{
      background-color: #2626260f !important;
      svg{
        g,path{
          fill: white !important;
        }
      }
    }
  }
  .showroom-bg-toggle{
    position: fixed;
    z-index: 5;
    top: 15px;
    right: 8rem;
    .bgTooltip{
      visibility: hidden;
      margin-top: 44px;
      position: absolute;
      right: -50px;
      color: rgba(255, 255, 255, 0.443);
      white-space: nowrap;
      font-size: 14px;
    }
    &:hover{
      .bgTooltip{
        visibility: visible;
      }
    }
    &.bgtoggle-microsite{
    right: 4rem;
    top: 20px;
    }
    svg{
      width: 25px;
      height: 25px;
      g,path{
        fill: #ec7600;
      }
    }
  }
  .logo-sharing{
    top: 0;
    margin-top: 7px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99;
    @media(max-width:1024px){
      svg{
        width: 20px;
        height: 20px;
      }
    }
    svg{
      width: 40px;
      height: 40px ;
      g,path{
        fill: rgba(255, 255, 255, 0.239);
      }
    }
  }
  @media screen and (max-width:768px){
    .logo-sharing{
      margin-top:1rem;
    }
  }
  .powered-sharing{
    color: white;
    display: flex;
    align-items: center;
    position: fixed;
    font-size: 16px;
    background-color: #2e2e2e6b !important;
    backdrop-filter: blur(20px);
    padding: 5px 10px;
    bottom: 0.8rem;
    border-radius: 5px;
    left: -0.5vw;
    z-index: 999;
    transform: scale(0.8);
    @media(max-width:1024px){
      visibility: hidden;
    }
    button{
      padding-left: 5px;
    }
    p{
      font-size: 16px;
      opacity: 0.9;
      color: rgb(255, 255, 255);
    }
    *{
      margin: 0;
    }
    a{
      opacity: 0.9;
      color: rgb(255, 255, 255);
      font-weight: bold;
      margin-left: 5px;
      &:hover{
        color: white;
      }
    }
  }
.tox-tinymce{
  top: 8px !important;
  position: fixed!important;
  z-index: 3;
  background-color: rgb(5, 5, 5);
}

.purple-mode{
  .tox-tinymce{
    background-color: #1e2836;
 }
}

.green-mode{
  .tox-tinymce{
    background-color: rgb(19, 68, 55);
 }
}

.blue-mode{
  .tox-tinymce{
    background-color: #104971;
 }
}

.bright-mode {
  .tox-tinymce{
    background-color: #2C2C2C;
 }
}


.tox .tox-tbtn--bespoke {
  background: none !important;
}
.tox-tinymce-inline .tox-editor-header{
  border: none !important;
}
.tox .tox-toolbar__group {
  padding: 0 5px !important;
}
.tox-toolbar__group button[title="Fonts"]{
margin-left: -8px!important;
}
.tox .tox-toolbar-overlord {
  background: none !important;
}
.tox-toolbar__group [aria-label="Background color"] {
  .tox-split-button__chevron {
    padding-top: 12px;
  }
}
.tox-toolbar__group [aria-label="Text color"], .tox-toolbar__group [aria-label="Background color"] {
  .tox-tbtn__icon-wrap {
    svg {
      zoom: 1.3;
      padding-top: 1px;
    }
  }
}

  .modal-dialog{
    @media screen and (min-width: 2000px) {
      max-width: 700px
    }
    @media screen and (min-width: 3000px) {
      max-width: 1000px;
    }
    @media screen and (min-width: 4000px) {
      max-width: 1350px;
    }
    .modal-content {
      background: #2e2e2e;
      border-radius: 18px;
    }
  }

  .tox-notifications-container {
    display: none !important;
  }
  
  a {
    text-decoration: none !important;
    &:hover{
      color: #ffff !important;
  }
  }

  // .tox-toolbar-overlord .tox-tbtn--disabled{
  //     display: none !important;
  // }
  .create-account-modal{
    .renameInput{
      align-items: flex-start;
    }
    .content{
      background: #232323;
      border-radius: 15px;
      padding: 45px;
      max-width: 550px;
    }
    label{
      margin-bottom: 15px;
    }
    label:first-child {
      font-weight: bold;
      margin-bottom: 25px;
      color:white;
      font-size: 19px;
    }
    .renameInput{
      .label-name{
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 15px;
        color: rgba(256,256,256,0.5);
      }
      input{
        border-radius: 5px;
        height: 45px;
        margin-top: 0px;
        text-align: left;
        font-size: 15px;
        padding-left: 20px;
        background-color: #333333;
        &::placeholder{
          color: #696767;
        }
      }
      .close-icon{
        position: absolute;
        top: 20px;
        right: 25px;
        width: 25px;
        height: 25px;
        svg{
          cursor: pointer;
          font-size: 20px;
          opacity: 0.5;
          color: #90949c;
        }
      }
    }
    button[type=reset]{
      cursor: pointer;
      padding: 0px 20px;
      height: 40px;
      margin-right: 15px;
      border-radius: 20px;
      border: none;
      color: #ffffff;
      opacity: 1;
      font-size: 15px;
      background: rgba(256,256,256,0.1);
      width: unset;
    }
    .btn-primary{
      cursor: pointer;
      padding: 0px 20px;
      height: 40px;
      border-radius: 20px;
      border: none;
      color: #ffffff;
      opacity: 1;
      font-size: 15px;
      background: #ec7600;
      width: unset;
    }
    .modal-footer-btns{
      margin-top: 35px;
    }
  }
  .tox-tinymce--disabled{
    display: none !important;
  }

  .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary{
    background: none !important;
    background-color: #ffffff00 !important;
    border: 0 !important;
    // position: fixed;
    // top: -15px;
    left: 0;
    width: 522px;
    // margin-left: -42px;
    // margin-top: 90px;
    margin-top:0rem;
    // @media (max-width: 1300px) {
    //   left: 35%;
    //   transform: scale(0.8);
    // }
    // @media (min-width: 1240px) {
    //   left: 38%;
    // }
  
    // @media (min-width: 1480px) {
    //   left: 43%;
    // }
    @media (max-width: 414px) {
      display: none !important;
    }
  }
  .tox-toolbar__primary {
    display: flex !important;
  }
  
  .tox .tox-collection--list .tox-collection__item--active {
    background-color: #000 !important;
}


  .tox-tbtn--select {
    width: 63px !important;
  }
  
  .tox-notification--warning{
    display: none;
  }
  .tox .tox-tbtn:hover {
    background: #535556 !important;
    color: #ffffff !important;
  }
  
  .tox .tox-tbtn:hover {
    background: #535556 !important;
    color: #ffffff !important;
  }
 

  .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
    border: none !important;
  }



.tox {
  .tox-tbtn {
    svg {
      fill: #FFFFFF !important;
      height: 22px;
    }
    &:active {
      background: none!important;
    }
  }
  .tox-split-button {
    &:hover {
      box-shadow: none!important;
    }
    &:focus {
      background: none!important;
    } 
  }
}
.tox .tox-tbtn {
  color: #ffffff !important;
}
.tox .tox-menu {
  background-color: #262626 !important;
  border: 1px solid #262626 !important;

}
.tox .tox-collection__item-label {
  color: #ffffff !important;
}

.tox .tox-tbtn__select-chevron{
  margin-top: 25%;
}

.tox.tox-tinymce-aux .tox-toolbar__overflow{
  background-color: #262626 !important;
  margin-top: 10px;
  max-width: unset !important;
}

.universalmenu-icon{
  z-index: 3!important;
}

.editor-hide{
  display: none !important;
  border: solid 1px red;
}
.tox .tox-tbtn:hover, .tox-tbtn--enabled,.tox .tox-tbtn--enabled:hover, .tox .tox-tbtn:hover,  .tox-tbtn--select:hover, .tox-tbtn--select:focus, .tox-collection__item:focus, .tox-collection__item:hover, .tox-toolbar__group:focus, .tox-collection__item--enabled, .tox-tbtn--enabled{
  border: none !important;
  outline: none !important;
  background-color: rgba(24, 24, 24, 0.822) !important;
  *{
    border: none !important;
    outline: none !important;
  }
}
.tox-toolbar{
  width: 100%;
  left: 40% !important;
}
.tox-toolbar--scrolling{
  margin-top: 2rem;
  top: -1% !important;
}

.bright-mode{
  .iDKEEO{
    background-color: #343434 !important;
  }
  .tabsBrightMode{
    color:#000000;
    opacity: 100%;
  }
  .titleBrightMode{
    color:#000000;
  }
  .previewBrightMode{
    background: #EFEFEF;
    box-shadow: 0px 3px 20px #0A274D1A;
  }
  .exitBrightMode{
    background: #E4E4E4;
    border-bottom: 1px solid #b8b8b8;
    color: black;
  }
  input{
    // background: #FFFFFF;
    background-color: transparent;
    color: black !important;
    opacity: 1 !important;
    &::placeholder{
      color: black !important;
    opacity: 1 !important;
    }
  }
  label{
    color: black!important;
  }
  .bgBrightMode{
    background: #DFDFDF;
  }
  .content-title{
    p{
      color: #000000!important;
    }
  }
  .description{
    span{
      color: #000000!important;
    }
  }
  .modal-content{
    background:#343434;
  }
  .cancelBrightMode{
    color:#343434;
    border-color:#343434;
  }
  .preview-template{
    background-color: #fcfcfc;
  }
  .magazine [id^=main-heading-page-6-06].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before,
  .magazine [id^=main-heading-type-4].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before,
  .magazine [id^=description-single-4].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before
  {
    color:white!important;
  }
  .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before{
    color:black;
  }
  .frame-theme{
    background-color:white;
    p{
      color:black;
    }
    span{
      color:#6c757d!important;
    }
    input,.inputGroup{
      background: rgb(234,234,234);
      #copy:hover{
        path{
          fill: #c5c5c5;
        }
      }
    }
  }
  .circle{
    background-color:#aeaeae;
  }
  h1{
    color: #313131c0;;
  }
  .container-microsite-nav:hover{
    button{
      color:#313131c0!important;
    }
  }
  .dropdown-manage-accounts{
    background: white;
    color: black;
    box-shadow: 0px 15px 40px #3e3e3e;
    .secBtn{
      color: black;
      background: rgba(0,0,0,0.05);
    }
    .addBtn{
      background: #ec7600;
    }
    .accounts-title{
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      h3 {
        opacity: 0.9;
      }
    }
    .manage-svg{
      background: rgba(0, 0, 0, 0.05);
    }
    .account-name{
      border: 1px solid rgba(0, 0, 0, 0.05);
    }
    .checked {
      background: #ec7600;
    }
    ::-webkit-scrollbar {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  .rename-portal-modal{
    .content{
      background: white;
      button:first-of-type{
        color: black;
        background: rgba(0, 0, 0, 0.05);
      }
    }
    .renameInput input{
      background: #b1b5bc24;
      &::placeholder{
        color: #000000a6;
      }
    }
    .close-icon svg{
      color: #000000;
      opacity: 1;
    }
  } 
}
.bright-mode{
  .detailed-part{
    .main-shared-text p{
      color:white;
    }
  }
  .uni-menu-wrapper{
    .icon-container{
     background-color: lightgray !important;
    }
  }
  .main-nav-templates{
    background-color: #131313;
  }
  .tox .tox-tbtn:hover, .tox-tbtn--enabled,.tox .tox-tbtn--enabled:hover, .tox .tox-tbtn:hover,  .tox-tbtn--select:hover, .tox-tbtn--select:focus, .tox-collection__item:focus, .tox-collection__item:hover, .tox-toolbar__group:focus, .tox-collection__item--enabled, .tox-tbtn--enabled{
    border: none !important;
    outline: none !important;
    background-color: rgba(161, 161, 161, 0.822) !important;
    *{
      border: none !important;
      outline: none !important;
    }
  }
  .heading-template{
    color: black;
  }
  .modal-wrapper{
    background-color:white;
  }
  .modal-title{
    color: black;
  }
  .templates-banner h2{
    color:black;
    span{
      color: #6c757d;
    }
  }
  .banner-title{
    color:black;
  }
  .banner-subtitle{
    color:#6c757d;
  }
  .nav-microsite{
    p{
      color:black;
    }
  }
  h2{
    color:#313131c0;;
  }
  .preview-navigation{
    span, b{
      color:black;
    }
  }
  .settings-icon{
    path{
      fill: #6c757d;
    }
  }
  .template-title{
    opacity:0.8;
  }
    .framethemes{
      background-color: #d1cfcf;
      input,.inputGroup{
        background-color: white;
      }
      .inputGroup{
       border-radius: 5px; 
      }
      h1,p,span,button{
        color:black!important;
        opacity: 1;
      }
      .input-copylink{
        p{
          color: black!important;
        }
      }
      button:last-child{
        color:#ec7600!important;
      }
    }
    .tox .tox-dialog{
      background-color: #d1cfcf!important;
      input,.tox-listboxfield, button[aria-label="Open link in..."],.tox-dialog__footer-end button{
        background-color: white !important;
      }
    }
    .tox-dialog__title,.tox .tox-dialog *{
      color:black!important;
    }
  
} 
button[aria-label="Fonts"]{
  width: 110px !important;
  svg{
    margin-top: -12px;
  }
}
.showrooms-bottom-content{
  opacity: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 2rem;
  &:hover{
    opacity: 1;
  }
  img{
    max-width:23px;
  margin-top: -6px;
  }
  .explore-showrooms-bottom{
    color: white;
    font-size: 14px;
    margin-top: 12px;
    margin-left: 8px;
  }
}
.templates-banner{
  display: flex;
  margin: 2rem 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  transform: scale(1.3);
  &.content-banner{
    margin-top: -3.7rem;
    margin-bottom: 2.5rem;
  .logo-logosvg{
    svg{
      transform: scale(1.5);
      margin-top: -6px;
      margin-right: 5px;
      g,path{
        fill: white;
      }
    }
  }
  }
  img{
    max-width: 25px;
    margin-top: -8px;
    margin-right: 6px;
  }
  h2{
    color: white;
    font-size: 14px;
    span{
      color: rgba(194, 194, 194, 0.82);
    }
  }
  .showroomlogo{
    max-width: 25.5px;
    margin-top: -10px;
    svg{
      g,path{
        fill: white;
      }
    }
  }
  .showroomtext{
    margin-left: 2px;
  }
}
.preview-template{
  background: #202020 0% 0% no-repeat padding-box ;
  box-shadow: 0px 3px 20px #000000;
  width: calc(100% - 100px);
  margin: 0 auto;
  border-radius: 1rem;
  min-height: 82.5vh;
  margin-top: 1rem;
  background: #202020 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #000000;
  border-radius: 20px;
  color: white;
}
.preview-templateBlur {
  width: calc(100% - 100px);
  margin: 0 auto;
  border-radius: 1rem;
  padding-bottom: 0.9rem;
  padding-top: 2rem;
  min-height: 82.5vh;
  position: relative;
  z-index: 2;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    content: '';
    z-index: -1;
    backdrop-filter: blur(50px);
  }
}
.nav-microsite{
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 1.5rem;
  z-index: 9999999;
 margin-right: 5rem;
 color: white;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity:0.8;
  margin-top: 0px;
  // position: relative;
  .container-microsite-nav{

    &:hover{
      button{
        visibility: visible;
        color: rgba(255, 255, 255, 0.564);
      }
      svg, img{
        opacity: 0.8;
      }
    }
    p{
      display: flex;
      gap: 4px;
      align-items: center;
    }
    svg{
      width: 20px;
      height: 20px;
      opacity: 1;
      cursor: pointer;
    }
  }
  img{
    max-width: 30px;
    opacity: 1;
    margin-left: 3px;
    cursor: pointer;
  }
button{
  position: absolute;
  margin-left: 5rem;
  cursor: pointer;
  font: normal normal bold 20px/24px Raleway;
  margin: 0;
  padding: 0;
  letter-spacing: 0px;
  color: rgba(210, 210, 210, 0.294);
  opacity: 1;
  transform: scale(0.75);
  cursor: pointer;
  visibility: hidden;
 margin-left: -10px;
 font-weight: 500;
//  margin-left: 18%;
margin-top: 3px;
  svg{
    margin-top: -4px;
  }
  &:hover{
    color: rgba(210, 210, 210, 0.782);
  }
}
}
.preview-navigation{
  background-color: transparent !important;
  position: relative !important;
  justify-content: center !important;
  min-height: 50px;
}
.microsite-navigation{
  position: relative !important;
  margin-bottom: 2rem;
  .nav-microsite{
    left: 0;
    right: 0;
    margin-top: 15px;
    position:absolute;
  }
}
.tox-icon .tox-tbtn__icon-wrap{
 margin-left: -50px !important;

}
button[aria-label="Insert/edit link"] {
  margin-left: -0.5rem !important;
  transform: scale(0.7);
  margin-right: -0.5rem!important;
}
.tox-dialog-wrap__backdrop{
  background-color: rgba(0, 0, 0, 0.576) !important;
  backdrop-filter: blur(26px);
}
.tox .tox-dialog{
  background-color: #2E2E2E !important;
  border-radius: 1rem !important;
  border: none !important;
  padding: 10px !important;
  div{
    color: rgb(212, 212, 212) !important;
  }
  input,.tox-listboxfield,button[aria-label="Open link in..."]{
    background-color: #3b3b3b !important;
    // margin: 7px 0;
    border: none !important;
    border-radius: 7px;
    color: white !important;
    &::placeholder{
      color: white !important;
    }
  }
  button[aria-label="Open link in..."]{
    padding: 5px 15px;
  }
  label{
  margin: 18px 0px 8px !important;
  }
  button{
    border: 1px solid rgba(128, 128, 128, 0.535) !important;
    border-radius: 8px !important;
    margin-top: 7px;
    color: rgb(207, 207, 207) !important;

    span{
    color: rgba(219, 219, 219, 0.72) !important;

    }
    svg{
      opacity: 0.5;
    }
  }
  *{
    background-color: transparent !important;
border: none !important;
    color: white !important;

  }
  button{
    border: none !important;
    color: rgba(222, 222, 222, 0.831) !important;
    font-weight: 500 !important;
  }
  .tox-button--naked{
    background-color: none !important;
    background: none !important;
    position: absolute;
    bottom: 40%;
    right: -15px;
  }
}
.tox-dialog__title{
  font-size: 18px !important;
}
.tox .tox-dialog .tox-dialog__footer{
  button{
    width: 110px;
    padding: 4px 15px;
    font-size: 14px;
  }
.tox-button{
    background-color: rgb(56, 56, 56) !important;

  }
}
div[title="Text color"]{
  //  background-color: red !important;
  display: flex;
  align-items: center;
  .tox-split-button__chevron{
    padding-top: 12px !important;
  }
   svg{
    g{
      fill: white !important;
    }
   }
  }
  .eye-preview{
    position: fixed;
    right: 0rem;
    z-index: 9999999;
    top: 1rem;
    &:hover{
      p{
        visibility: visible;
      }
    }
    p{
      width: max-content;
      font-size: 12px;
      opacity: 0.8;
      margin-top: 3px;
      visibility: hidden;
    }
  
    svg{
      opacity: 0.5;
      width: 23px;
      height: 23px;
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
      line{
        stroke: rgba(255, 255, 255, 0.724);
      }
      g,path{
        fill: rgba(255, 255, 255, 0.753) !important;
      }
    }
  }
  
  .tox-menu{
    &.tox-swatches-menu{
      background-color: #2626260f !important;
      svg{
        g,path{
          fill: white !important;
        }
      }
    }
  }
  .showroom-bg-toggle{
    position: fixed;
    z-index: 5;
    top: 17px;
    right: 8rem;
    &.bgtoggle-microsite{
    right: 4rem;
    top: 20px;
    }
    svg{
      width: 25px;
      height: 25px;
      g,path{
        fill: #ec7600;
      }
    }
  }
// @media screen and (min-width: 1024px) and (max-height: 1366px)  {
//   .main-shared-text .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{
//     font-size: 14px;
//   }
// }
@media(max-width: 1024px){
  .main-wrapper-coverpages, .main-wrapper-templates {
    margin-top: -1.5rem!important;
  }
  .general-page-wrapper .bottom-main-btns{
    display: none;
  }
}

@media screen and (min-height: 722px) {
  .main-shared-text .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{
    font-size: 2em;
  }
  .main-shared-text .mce-content-body[data-mce-placeholder="Add subheading here"]:not(.mce-visualblocks)::before{
    font-size: 18px;
    line-height: 1.4rem;
  }
  .main-shared-text .mce-content-body[data-mce-placeholder="Add description here"]:not(.mce-visualblocks)::before{
    font-size: 15px;
    line-height: 1.4rem;
  }
}
@media screen and (min-height: 900px) {
  .share-container .frame-theme{
    padding: 2rem 3rem;
    min-width: 38rem;
    max-width: 38rem;
    p{
      margin: 20px 0 10px 0;
      font-size: 16px;
    }
    span{
      font-size: 14px;
    }
    input{
      font-size: 15px;
    }
    &.share{
      button{
        height: 35px;
        font: normal normal 600 14px/19px Raleway;
        width: 160px;
        padding: 7px 44px;
      }
    }
  }  
  .iconContainer{
    width: 40px;
    height:40px;
    svg{
      transform: scale(0.9);
    }
  }
  .main-shared-text .mce-content-body[data-mce-placeholder="Add description here"]:not(.mce-visualblocks)::before{
    font-size: 17px;

  }
}

@media screen and (max-height: 900px){
  .general-page-wrapper{
    .main-wrapper-templates{
      margin-top: 2rem;
    } 
    .main-wrapper-coverpages{
      margin-top: 14rem;
      bottom: 200px;
    }
  }
}
@media screen and (max-height: 725px){
  .general-page-wrapper{
    .main-wrapper-coverpages, .main-wrapper-templates{
      padding-bottom: 0.5rem;
    }
    .main-wrapper-coverpages{
      bottom: 150px;
    }
  } 
  .showrooms-bottom-content{
    padding-bottom: 0;
  }
}
@media screen and (max-height: 580px){
  .general-page-wrapper{
    .main-wrapper-coverpages, .main-wrapper-templates{
      margin-top: 0;
      padding-bottom: 0;
      bottom: 0;
    }
    .coverpages{
      top: -20px;
    }
  } 
  .dropdown-manage-accounts{
    zoom: 0.95;
  }
}

@media screen and (max-height: 650px){
  .showroom-bg-toggle{
    top: 12px;
    right: 6.6rem;
    .bgTooltip{
      font-size: 12px;
    }
  }
  .preview-templateBlur {
    padding-top: 0;
    padding-bottom: 0;
  }
  .template-11{
    height: 89vh;
    padding-top: 2.8rem;
    width: calc(100% - 100px);
    margin: 0 auto;
  }
  .shared-template.portrait-template-11 .content {
    margin-left: 19%;
  }
}

@media screen and (min-width: 1300px) {
  .tox-tbtn--select {
    width: 70px !important;
  }
}

@media screen and (min-width: 1550px) {
  .preview-templateBlur {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media(min-width:2000px){
  .templates-banner{
    &.content-banner{
      margin-top: -2rem;
      margin-bottom: 5rem;
    }
  }
  .content-banner{
    img{
      transform: scale(1.5);
    }
    h2{
      margin-left: 1rem;
      font-size: 18px;
    }
  }
  .logo-logosvg svg{
    margin-right: 0px;
    transform: scale(2.0);
  }
  .framethemes{
    button{
      font-size: 22px;
    }
  }
}

@media screen and (min-width: 2560px) {
  // .preview-template{
  //   min-height: 65.5vh;
  // }

  .templates-banner{
    .showroomtext, h2{
      width: auto;
    }
    h2{
      font-size: 22px;
    }
    &.content-banner{ 
      margin-top: -3.7rem;
      margin-bottom: 3rem;
    }
  }
  .nav-microsite{
    .container-microsite-nav{
      p{
        font-size: 30px;
      }
    }
  }
  .main-shared-text #main-heading[data-mce-placeholder]:not(.mce-visualblocks)::before{
    font-size: 3em;
  }
  .main-shared-text .mce-content-body[data-mce-placeholder="Add subheading here"]:not(.mce-visualblocks)::before{
    font-size: 22px;
    line-height: 2.5rem;
  }
  .main-shared-text .mce-content-body[data-mce-placeholder="Add description here"]:not(.mce-visualblocks)::before{
    font-size: 21px;
    line-height: 2.5rem;
  }
  .tox-dialog{
    transform: scale(1.25);
  }
  .dropdown-manage-accounts{
    zoom: 1.4;
  }
  .showrooms-bottom-content{
    zoom: 1.4;
  }
  .nav-microsite img{
    max-width: 35px;
  }
  .tox-toolbar__group [aria-label="Font sizes"] {
    width: 100px !important;
  }
}
@media screen and (min-width: 3000px) {
  .templates-banner{
    gap:5px;
    h2, .showroomtext{
      font-size: 34px;
    }
    .showroomlogo, img{
      max-width: 28px;
    }
    &.content-banner{ 
      margin-top: -0.5rem;
      margin-bottom: 5rem;
    }
  }
  .framethemes{
    .buttons{
      bottom: 2.7rem;
      button{
        font-size: 24px;
      }
    }
  }
  .main-shared-text #main-heading[data-mce-placeholder]:not(.mce-visualblocks)::before{
    font-size: 4em;
  }
  .main-shared-text .mce-content-body[data-mce-placeholder="Add subheading here"]:not(.mce-visualblocks)::before{
    font-size: 35px;
    line-height: 3.5rem;
  }
  .main-shared-text .mce-content-body[data-mce-placeholder="Add description here"]:not(.mce-visualblocks)::before{
    font-size: 34px;
    line-height: 3.5rem;
  }
  .nav-microsite{
    .container-microsite-nav{
      p{
        font-size: 40px;
      }
    }
  }
  .tox-tinymce{
    top: 16px !important;
  }
  .nav-microsite img{
    max-width: 50px;
  }
  .tox-dialog{
    transform: scale(2);
  }
  .dropdown-manage-accounts{
    zoom: 2.2;
  }
  .showrooms-bottom-content{
    zoom: 2.2;
  }
  .templates-banner{
    .logo-logosvg{
      zoom: 1.8;
    }
  }
  button[aria-label=Fonts] svg{
    margin-top: 5px;
  }
  .tox-toolbar__group [aria-label="Text color"] .tox-split-button__chevron svg{
    margin-top: 17px;
  }
  .tox-toolbar__group [aria-label="Background color"] .tox-split-button__chevron svg{
    margin-top: 18px;
  }
  button[aria-label="Text color"] svg{
    margin-top: 5px;
  }
  .showroom-bg-toggle{
    svg{
      width: 35px;
      height: 35px;
    }
  }
}
@media screen and (min-width: 4000px) {
  .templates-banner{
    gap:10px;
    .showroomtext, h2{
      font-size: 40px;
    }
    .showroomlogo, img{
      max-width: 45px;
    }
    &.content-banner{
      margin-top: 3.3rem;
    }
  }
  .main-shared-text #main-heading[data-mce-placeholder]:not(.mce-visualblocks)::before{
    font-size: 5em;
  }
  .main-shared-text .mce-content-body[data-mce-placeholder="Add subheading here"]:not(.mce-visualblocks)::before{
    font-size: 49px;
    line-height: 4.5rem;
  }
  .main-shared-text .mce-content-body[data-mce-placeholder="Add description here"]:not(.mce-visualblocks)::before{
    font-size: 48px;
    line-height: 4.5rem;
  }
  .nav-microsite{
    .container-microsite-nav{
      button{
        font-size: 55px;
        margin-top: 20px;
      }
    }
    img{
      max-width: 65px;
    }
  }
  .dropdown-manage-accounts{
    zoom: 2.8;
  }
  .showrooms-bottom-content{
    zoom: 2.8;
  }
  .showroom-bg-toggle{
    top: 22px;
    right: 14rem;
    svg{
      width: 50px;
      height: 50px;
    }
  }
  .tox-tinymce-inline .tox-editor-header { 
    max-width: 1800px!important;
  }
}

.purple-mode{
  .preview-template,.show-room-item{
    background-color: rgb(38, 50, 70);
  }
  .tox .tox-menu{
    background-color: #2D3442 !important;
    border-color: #2D3442 !important;
  }
  .tox .tox-collection--list .tox-collection__item--active{
    background-color: #404d65 !important;
  }
  .modal-wrapper,.frame-theme,.popup-frame{
    background-color: rgb(38, 50, 70);
    .inputGroup,.iconContainer{
      background-color: rgba(83, 83, 83, 0.1176470588);
    };
    .title{
      color: #CCCCCC;
    }
    h2{
      color:white;
    }
  }
  .modal-title, .cancel-button{
    color: white;
  }
    .framethemes{
      background-color: #1e2836;
      input,.inputGroup{
        background-color: #2d3542;
      }
      .inputGroup{
        border-radius: 5px; 
       }
    }
    .iconContainer{
      background-color: #2d3542;
    }
    .tox .tox-dialog{
      background-color: #1e2836!important;
      input,.tox-listboxfield, button[aria-label="Open link in..."],.tox-dialog__footer-end button{
        background-color: #2d3542 !important;
      }
      svg path{
        fill: white !important;
      }
    }
  
  .containerSlider #right, #left{
          background: #1d2634;
          &:hover{
            background: #2f3745;
          }
        }
        .editor .tooltiptext,.template-single-card .tooltip-container .dropdown{
          background-color: #2d3542;
          color:white;
        }
        .tooltips .tooltiptext::after {
          border-color: transparent #2d3542 transparent transparent;
        }
        .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
          border-bottom-color: #2d3542;
        }
        .react-datepicker__header{
          background-color: #2d3542;
          border-bottom: 1px solid #2d3542;
        }
        .react-datepicker{
          background-color: #2d3542;
          border: 1px solid #2d3542;
        }
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name,.react-datepicker__current-month{
          color:white;
        }
        .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover,.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range{
          background-color: #3c4656!important;
          color:white!important;
        }
        .dropdown-manage-accounts{
          background:#191B26;
          color: rgb(255, 255, 255);
          box-shadow: 0px 15px 40px #191b26;
          .secBtn{
            color: rgb(255, 255, 255);
            background: rgba(256,256,256,0.1);
          }
          .addBtn{
            background: #507ed0;
          }
          label{
            color: white!important;
          }
          .manage-svg{
            svg{
              fill: #507ed0;
            }
          }
          .account-name{
            border: 1px solid rgba(256,256,256,0.05);
          }
          .checked {
            background: #507ed0;
          }
          ::-webkit-scrollbar {
            background-color: #ffffff1a;
          }
        }
        .rename-portal-modal{
          .content{
            background: #191B26;
            input{
              background: rgba(256,256,256,0.05);
            }
            button:nth-of-type(2){
              background: #507ed0;
            }
          }
        }
  .modals-backgrounds{
    background-color: #1e2836;
  }
  .magazine{
    background-color: #232c3a;
    .designtool-template, .popupContent, .templateRowContainer, .select-templates{
        background-color: #232c3a!important;
    }
    .deletePopupContainer{
      div:nth-child(1) {
        background-color: #2c3442;
      }
      button:nth-child(1){
        background-color: rgba(80, 125, 208, 0.7882352941);
      }  
      button:nth-child(2){
        background-color: rgba(43, 71, 119, 0.9843137255) !important;
      }
      h4{
        color: white;
      }
    }
    .popupContent{
      input{
        background-color: #2d3542;
      }
    }
    .see-content{
        background-color: #2d3542;
    }
  }
}
.green-mode{
  .magazine{
    background-color: rgba(31, 51, 46, 0.894);
    .templateRowContainer, .select-templates{
      background-color: #144236!important;
    }
    .designtool-template {
      background-color: rgba(31, 51, 46, 0.894)!important;
        opacity: 0.9;
    }
    .popupContent{
      background-color: #144236!important;
      input{
        background-color: #195444;
      }
    }
    .deletePopupContainer{
      div:nth-child(1) {
        background-color: #2a453d;
      }
      button:nth-child(1){
        background-color: #078369;
      }  
      button:nth-child(2){
        background-color: #004938 !important;
      }
      h4{
        color: white;
      }
    }
    .see-content{
      background-color: #195444;
    }
  }

  .preview-template,.show-room-item{
    background-color: #21332F;
  }
  .tox .tox-menu{
    background-color: #2a453d !important;
    border-color: #2a453d !important;
  }
  .tox .tox-collection--list .tox-collection__item--active{
    background-color: #335f52 !important;
  }
  .modal-wrapper,.frame-theme,.popup-frame{
    background-color: #21332F;
    .inputGroup,.iconContainer{
      background-color: rgba(83, 83, 83, 0.1176470588);
    };
    .title{
      color: #CCCCCC;
    }
    h2{
      color:white;
    }
  }
  .modal-title, .cancel-button{
    color: white;
  }
    .framethemes{
      background-color: #144236;
      input,.inputGroup{
        background-color: #195444;
      }
      .inputGroup{
        border-radius: 5px; 
       }
    }
    .iconContainer{
      background-color: #195444;
    }
    .tox .tox-dialog{
      background-color: #195444!important;
      input,.tox-listboxfield, button[aria-label="Open link in..."],.tox-dialog__footer-end button{
        background-color: #1f6552 !important;
      }
      svg path{
        fill: white !important;
      }
    }
    .containerSlider #right, #left{
      background: #144236;
      &:hover{
        background: #2b3c38;
      }
    }
    .editor .tooltiptext,.template-single-card .tooltip-container .dropdown{
      background-color: #195444;
      color:white;
    }
    .tooltips .tooltiptext::after {
      border-color: transparent #195444 transparent transparent;
    }
      .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
        border-bottom-color: #195444;
      }
      .react-datepicker__header{
        background-color: #195444;
        border-bottom: 1px solid #195444;
      }
      .react-datepicker{
        background-color: #195444;
        border: 1px solid #195444;
      }
      .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name,.react-datepicker__current-month{
        color:white;
      }
      .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover,.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range{
        background-color: #236e5a!important;
        color:white!important;
      }  
      .dropdown-manage-accounts{
        background: #121C19;
        color: rgb(255, 255, 255);
        box-shadow: 0px 15px 40px #121c19;
        .secBtn{
          color: rgb(255, 255, 255);
          background: rgba(256,256,256,0.1);
        }
        .addBtn{
          background: #059375;
        }
        label{
          color: white!important;
        }
        .manage-svg{
          background: rgba(256,256,256,0.05);
          svg{
            fill: #059375;
          }
        }
        .account-name{
          border: 1px solid rgba(256,256,256,0.05);
        }
        .checked {
          background: #059375;
        }
        ::-webkit-scrollbar {
          background-color: #ffffff0d;
        }
      }
      .rename-portal-modal{
        .content{
          background: #121C19;
          input{
            background: rgba(256,256,256,0.05);
          }
          button:nth-of-type(2){
            background: #059375;
          }
        }
      }
  .tox .tox-dialog button {
    opacity: 0.95;
  }
  .modals-backgrounds{
    background-color: rgb(19, 68, 55);
  }
}
.blue-mode{
  .magazine{
    background-color: #01365cde;
    position: relative;
    .templateRowContainer, .select-templates{
      background-color: #104971!important;
    }
    .designtool-template {
      background-color: rgb(5 51 84) !important;
    }
    .popupContent{
      background-color: #043253!important;
      input{
        background-color: #104971;
      }
    }
    .deletePopupContainer{
      div:nth-child(1) {
        background-color: #093c5e;
      }
      button:nth-child(1){
        background-color: #0175B2;
      }  
      button:nth-child(2){
        background-color: rgba(5, 94, 142, 0.8078431373) !important;
      }
      h4{
        color: white;
      }
    }
    .see-content{
      background-color: rgba(5, 94, 142, 0.8078431373);
    }
  }
  .preview-template,.show-room-item{
    background-color: #0C314B;
  }
  .tox .tox-menu{
    background-color: #184665 !important;
    border-color: #184665 !important;
  }
  .tox .tox-collection--list .tox-collection__item--active{
    background-color: #1b5278 !important;
  }
  .modal-wrapper,.frame-theme,.popup-frame{
    background-color: #0C314B;
    .inputGroup,.iconContainer{
      background-color: rgba(83, 83, 83, 0.1176470588);
    };
    .title{
      color: #CCCCCC;
    }
    h2{
      color:white;
    }
  }
  .modal-title, .cancel-button{
    color: white;
  }
    .framethemes{
    background-color: #043253;
    input,.inputGroup{
      background-color: #104971;
    }
    .inputGroup{
      border-radius: 5px; 
     }
  }
  .iconContainer{
    background-color: #104971;
  }
  .tox .tox-dialog{
    background-color: #043253!important;
    input,.tox-listboxfield, button[aria-label="Open link in..."],.tox-dialog__footer-end button{
      background-color: #104971 !important;
    }
    svg path{
      fill: white !important;
    }
  }
 .containerSlider #right, #left{
      background: #104971;
      &:hover{
        background: #133e5d;
      }
    }
    .editor .tooltiptext,.template-single-card .tooltip-container .dropdown{
      background-color: #104971;
      color:white;
    }
    .tooltips .tooltiptext::after {
      border-color: transparent #104971 transparent transparent;
    }
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
      border-bottom-color: #104971;
    }
    .react-datepicker__header{
      background-color: #104971;
      border-bottom: 1px solid #104971;
    }
    .react-datepicker{
      background-color: #104971;
      border: 1px solid #104971;
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name,.react-datepicker__current-month{
      color:white;
    }
    .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover,.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range{
      background-color: #216ba5!important;
      color:white!important;
    }
    .dropdown-manage-accounts{
      background: #02253b;
      color: rgb(255, 255, 255);
      box-shadow: 0px 15px 40px #02253b;
      .secBtn{
        color: rgb(255, 255, 255);
        background: rgba(256,256,256,0.1);
      }
      .addBtn{
        background: #1669aa;
      }
      .accounts-title{
        border: 1px solid rgba(256,256,256,0.05);
      }
      label{
        color: white!important;
      }
      .manage-svg{
        background: rgba(256,256,256,0.05);
        svg{
          fill: #1669aa;
        }
      }
      .account-name{
        border: 1px solid rgba(256,256,256,0.05);
      }
      .checked {
        background: #1669aa;
      }
      ::-webkit-scrollbar {
        background-color: #ffffff1a;
      }
    }
    .rename-portal-modal{
      .content{
        background: #02253b;
        input{
          background: rgba(256,256,256,0.05);
        }
        button:nth-of-type(2){
          background: #1669aa;
        }
      }
    }
  .modals-backgrounds{
    background-color: #104971;
  }
}
.purple-mode,.green-mode,.blue-mode{
  .tox .tox-tbtn:hover, .tox-tbtn--enabled,.tox .tox-tbtn--enabled:hover, .tox .tox-tbtn:hover,  .tox-tbtn--select:hover, .tox-tbtn--select:focus, .tox-collection__item:focus, .tox-collection__item:hover, .tox-toolbar__group:focus, .tox-collection__item--enabled, .tox-tbtn--enabled{
    border: none !important;
    outline: none !important;
    background-color: rgba(161, 161, 161, 0.149) !important;
    *{
      border: none !important;
      outline: none !important;
    }
  }
  .tox .tox-menu{
    svg{
      g,path{
        fill: white !important;
      }
    }
  }
  .react-datepicker__day--disabled{
    color: #ffffff78!important;
  }
  .sharevia-btn{
    color: #ec7600!important;
  }
  .framethemes{
      button:last-child{
        color:white;
        opacity: 1;
      }
  }
  .input-copylink{
    p{
      color: white;
    }
  }
  .back-btn{
    color: white;
    opacity: 0.8;
    border: none;
  }
  .nav-microsite{
    .container-microsite-nav button{
      color: #ffffff;
    }
  }
  .magazine{
    position: relative;
  }
}
.purple-mode{
  .shareContainer{
  .shareFrame{
    background-color: #2D3442;
    .inputGroup,.iconContainer{
      background-color: rgba(218, 218, 218, 0.057);
    }
  }
  }
  .finishmicrosite{
    background-color: #507dd0c9 !important;
  }
  .options-container input:checked:after,.circle.active{
    background-color:#507dd0c9 ;
  }
  .bottom-btn{
    button{
      &:nth-child(1){
        background-color: #507dd0c9 !important;
      }
      &:nth-child(2),  &:nth-child(3){
        background-color: rgba(43, 71, 119, 0.9843137255) !important;
      }
    }
  }
  .bgBrightMode{
    background-color: #2c3442;
    button{
     &:nth-child(1){
      background-color: #507dd0c9;
     }
     &:nth-child(2){
      background-color: rgba(43, 71, 119, 0.9843137255) !important;
    }
    }
  }
  .shared-popup{
    .buttons-popup{
      button:first-child{
        background-color: rgba(80, 125, 208, 0.7882352941);
      }
    }
    .tox {
      .tox-dialog{
      background-color: #2c3442 !important;

      }
    }
    .popup-frame,.tox-dialog{
      background-color: #2f3541;
      background-color: #2c3442 !important;
      .continuebtn{
        button{
          background-color: #507dd0c9; 
        }
      }

    input{
      background-color: rgba(218, 218, 218, 0.057);
    }
    .buttons{
      .finish{
        &:nth-child(1){
          background-color: #507dd0c9 !important;
        }
        &:nth-child(2){
          background-color: rgba(43, 71, 119, 0.9843137255) !important;
        }
      }
    }
  }
}
}



.green-mode{
  .shareContainer{
    .shareFrame{
      background-color: #2a453d;
      .inputGroup,.iconContainer{
        background-color: rgba(218, 218, 218, 0.057);
      }
    }
    }
  .finishmicrosite{
    background-color: #078369 !important;
  }
  .options-container input:checked:after,.circle.active{
    background-color:#078369 ;
  }
  .bottom-btn{
    button{
      &:nth-child(1){
        background-color: #078369 !important;
      }
      &:nth-child(2),  &:nth-child(3){
        background-color: #004938 !important;
      }
    }
  }
  .bgBrightMode{
    background-color: #2a453d;
    button{
     &:nth-child(1){
      background-color: #078369;
     }
     &:nth-child(2){
      background-color: #004938 !important;
    }
    }
  }
  .shared-popup{
    .buttons-popup{
      button:first-child{
        background-color: #078369;
      }
      button:nth-child(2){
        background-color: #004938;
      }
    }
    .popup-frame{
      background-color: #2f3541;
      background-color: #2a453d;
      .continuebtn{
        button{
          background-color: #078369; 
        }
      }

    input{
      background-color: rgba(218, 218, 218, 0.057);
    }
    .buttons{
     .finish{
      &:nth-child(1){
        background-color: #078369 !important;
      }
      &:nth-child(2){
        background-color: #004938 !important;
      }
     }
    }
    }
}
}
.blue-mode{
  .shareContainer{
    .shareFrame{
      background-color: #093c5e;
      .inputGroup,.iconContainer{
        background-color: rgba(218, 218, 218, 0.057);
      }
    }
    }
  .finishmicrosite{
    background-color: #0175B2 !important;
  }
  .options-container input:checked:after,.circle.active{
    background-color:#0175B2 ;
  }
  .bottom-btn{
    button{
      &:nth-child(1){
        background-color: #0175B2 !important;
      }
      &:nth-child(2),  &:nth-child(3){
        background-color: rgba(5, 94, 142, 0.8078431373) !important;
      }
    }
  }
  .bgBrightMode{
    background-color: #093c5e;
    button{
     &:nth-child(1){
      background-color: #0175B2;
     }
     &:nth-child(2){
      background-color: rgba(5, 94, 142, 0.8078431373) !important;
    }
    }
  }
  .shared-popup{
    .buttons-popup{
      button:first-child{
        background-color: #0175B2;
      }
    }
    .popup-frame{
      background-color: #2f3541;
      background-color: #093c5e;
      .continuebtn{
        button{
          background-color: #0175B2; 
        }
      }

    input{
      background-color: rgba(218, 218, 218, 0.057);
    }
    .buttons{
     .finish{
      &:nth-child(1){
        background-color: #0175B2 !important;
      }
      &:nth-child(2){
        background-color: rgba(5, 94, 142, 0.8078431373) !important;
      }
     }
    }
    }
  }
}
.dark-mode{
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    border-bottom-color: #3b3b3b;
  }
  .react-datepicker__header{
    background-color: #3b3b3b;
    border-bottom: 1px solid #3b3b3b;
  }
  .react-datepicker__day--disabled{
    color: #9b9a9a!important;
  }
  .react-datepicker{
    background-color: #3b3b3b;
    border: 1px solid #3b3b3b;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name,.react-datepicker__current-month{
    color:white;
  }
  .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover,.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range{
    background-color: #4f4d4d75!important;
    color:white!important;
  }
  .dropdown-manage-accounts{
    background: #232323;
    color: rgb(255, 255, 255);
    box-shadow: 0px 15px 40px #0a090a;
    .secBtn{
      color: rgb(255, 255, 255);
      background: rgba(256,256,256,0.1);
    }
    .addBtn{
      background: #ec7600;
    }
    label{
      color: white!important;
    }
    .manage-svg{
      background: rgba(256,256,256,0.05);
      svg{
        fill: #ec7600;
      }
    }
    .account-name{
      border: 1px solid rgba(256,256,256,0.05);
    }
    .checked {
      background: #ec7600;
    }
  }
  .main-nav-templates {
    .container-microsite-nav {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
  .card-room{
    svg{
      path{
        fill: #ffffff;
      }
    }
  }
}

.activetheme{
  border-color: white !important;
}
.columntemp1{
  background-image: url(./assets/images/covernew2.jpg) !important;

}
.columntemp1-2{
  background-image: url(./assets/images/covernew3.jpg) !important;

}
.column3{
  background-image: url(./assets/images/cover14t.jpg) !important;
}
.image--three{
  &.columntemp2{
  background-image: url(./assets/images/covernew1.jpg) !important;

  }
}
.customizer-open{
  overflow: hidden;
}
button[title="Paste as text"],button[title="Paste"]{
  display: none!important;
}
.bright-mode{
  .bottom-main-btns{
    svg{
      g,path{
        fill: gray;
      }
    }
  }
  .back-btn{
    color: black;
    opacity: 0.6;
    border: none;
  }
  .added-tags{
    background: rgb(30 30 30 / 22%);
    span{
      color: white!important;
    }
  }
  .showrooms-bottom-content{
    opacity: 0.7;
    &:hover{
      opacity: 1;
    }
  }
  .main-nav-templates {
    .container-microsite-nav {
      svg {
        path {
          fill: black;
          opacity: 0.6;
        }
      }
    }
  }
  .modals-backgrounds{
    background-color: #dedede;
    button{
      color: black;
    }
  }
}

#Mw-Toast-Wrap{
  top: 8px !important;
}


.tox {
  .tox-dialog{
    padding: 30px !important;
    padding-top: 80px !important;
    z-index: 1;
    min-width: 33rem;
    max-width: 33rem;
    min-height: 22.3rem;
    max-height:22.3rem ;
    &::after{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background-color: black;
      z-index: -1;
      content: '';
      background-image: url(./assets/images/modalbg3.jpeg);
      background-position: center;
      background-size: cover;
    }
    button,.tox-listboxfield{
      border-radius: 30px !important;
    }
    button{
      background: none !important;
      opacity: 0.7;
      font-size: 17px;
      padding: 0 ;
      &:hover{
        opacity: 1;
      }
      &:nth-child(2){
        color: #ec7600 !important;
      }
      &:nth-child(1){
        margin-right: 10px;
      }
    }
    .tox-form{
      input{
        border-radius: 30px;
        padding-left: 15px;
      }
      .tox-form__group{
        &:nth-child(3){
          display: none;
        }
      }
    }
  }
}
.microsite-navigation{
  background-color: transparent !important;
}


.green-mode{
  .framethemes{
    .imagepart{
      background-image: url(./assets/images/banner-themes.png);
      &::before {
        background-color: #2552468f;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
  .tox-dialog{
    &::after{
      background-image: url(./assets/images/banner-themes.png);
    }
      &::before {
        background-color: #19875473;
        content: "";
        display: block;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
  }
}
.purple-mode{
  .framethemes{
    .imagepart{
      background-image: url(./assets/images/banner-themes.png);
      &::before {
        background-color: #414e64a8;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
  .tox-dialog{
    &::after{
      background-image: url(./assets/images/banner-themes.png);
    }
      &::before {
        background-color: #414e64a8;
        content: "";
        display: block;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
  }
}
.blue-mode{
  .framethemes{
    .imagepart{
      background-image: url(./assets/images/banner-themes.png);
      &::before {
        background-color: #2a394f7d;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
  .tox-dialog{
    &::after{
      background-image: url(./assets/images/banner-themes.png);
    }
      &::before {
        background-color: #2a394f7d;
        content: "";
        display: block;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
  }
}
.loading__popover{
  position: fixed !important;
  z-index: 99999 !important;
  background-color: #111111 !important;
  min-width: 430px !important;
  padding:22px 30px !important;
  top: 12px !important;
  left: auto !important;
  right: 0px !important;
  color: white;
  height: 90px;
  img{
    max-width: 25px;
    margin-left: 20px;
    margin-top: -5px
  }
}

.lite-popup{
  color: white;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  button{
    background-color: #ec7600;
    color: white;
    font-size: 14px;
    padding: 15px 30px;
    border-radius: 30px;
    margin-top: 10px;
  }
  h1{
    font-size: 5rem;
  }
}


  .liteFull{
    position: relative;
    &::after{
      position: absolute;
      top: 0;
      left: -5%;
      width: 110%;
      height: 90%;
      backdrop-filter: blur(30px);
      // filter: blur(10px);
      content: '';
      z-index: 9;
      border-radius: 20px;
      background-color: #14141448;
    }
  }

.examples-slider{
  max-width: 100%;
  max-height: 40vh;
  // position: absolute;
  top:8%;
  left: 0;
  z-index: 999;
  margin: 0 auto;
  .slick-slide{
    margin: 0 10px !important;
    img{
      border-radius: 5px;
      aspect-ratio: 16/9;
      width: 100%;
    }
  }
}
.main-wrapper-coverpages{
  &.liteFull{
    background-color: #00000000;
  }
.examples-slider{
top: 25%;
}
}
.limitmodal{
  .examples-slider{
    margin: 30px 0;
    max-height:130px;
    margin-top: 4rem;
}
}
.tox.tox-tinymce-inline .tox-editor-header{
  background-color: transparent !important;
}
.tox-editor-header{
  background-color: transparent !important;
}
.tox{
  button,.tox-tbtn{
    background: transparent !important;
  }
}
div[aria-label="Background color Black"]{
  display: none !important;
}
div[data-mce-name="backcolor"]{
  display: none !important;
}
.microsite-actions{
  position:  fixed !important; 
  display: flex !important;
  width: 100%;
  padding:15px 20px;
  background-color: rgba(0, 0, 0, 0.563);
  backdrop-filter: blur(30px);
  bottom: -70px !important;
  left: 0 !important;
  z-index: 99999;
  flex-direction: row !important;
  justify-content: center;
  gap: 20px;
  .finishmicrosite{
    margin: 0 !important;
  }
  button{
    background-color: rgb(63, 63, 63) !important;
    border-radius: 8px !important;
    padding: 10px !important;
    &:hover{
    background-color: rgb(43, 43, 43) !important;   
    }
  }
}
.datepicker-wrapper {
  width: 100%;
  display: flex !important;
}

.mce-content-body{
      strong{
        font-family: inherit;
      }
}