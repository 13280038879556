.create-microsite {
  // width: 100%;
  align-items: flex-end;
  color: white;
  position: absolute;
  right: 280px;
  justify-content: flex-end;
  margin-top: 115px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}
.choose-coverpage{
  color: rgba(255, 255, 255, 0.665);
  font-size: 20px;
}

.micrositeBtn{
  position: fixed;
  top: 15px;
  right: 120px;
  padding: 5px 15px;
  z-index: 99;
  color: #ec7600;
  border-radius: 30px;
  font-size: 14px;
  width: fit-content;
  transition: all 0.1s ease-in-out;
  &:hover{
    font-weight: 900;
    transform: scale(1.02);
  }
}

.options-container {
  color: white;
  font-size: 15px;
  text-align: left;
  position: relative;
  padding: 1rem 0rem;
  label{
    span{
      margin-right: 10px;
    }
  }

  .close-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    z-index: 9999;
    right: 0;
    top: 0;

    .close {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      opacity: 0.7;
      background-color: rgba(128, 128, 128, 0.247);

      &:hover {
        opacity: 1;
      }

      svg {
        // padding-top:1px;
        // padding-left: 1px;
        transform: scale(0.9);
        opacity: 1;

        g,
        path {
          fill: white;
        }
      }
    }
  }

  h2 {
    font-size: 19px;
    padding-bottom: 1rem;
  }

  label {
    background-color: none;
    display: flex;
    margin: 10px 0;
    align-items: center;
    font-size: 14px;
  }

  input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    // background-color: white;
    /* Not removed via appearance */
    margin: 0;
    border: 1px solid gray;
    margin-top: 3px;
    margin-right: 20px;
    font: inherit;
    color: currentColor;
    width: 18px;
    height: 18px;
    // border: 0.15em solid currentColor;
    // border-radius: 10px;
    transform: translateY(-0.075em);
    background-color: transparent;
    display: grid;
    place-content: center;
    border-radius: 50%;
  }

  input[type="radio"]::before {
    content: "";
    width: 20px;
    height: 20px;
    // border-radius: 10px;
    transform: scale(0);
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input:checked:after {
    color: #fff;
    content: "";
    height: 11px;
    position: absolute;
    width: 11px;
    z-index: -1;
    background-color: #ec6700;
    border-radius: 50%;
    margin-left: 2.5px;
    margin-top: 2.5px;
  }

  //   input[type="radio"]:focus {
  //   background-color: #ec6700;
  // //   content: "✓";
  // //   color: white;
  //   }
}
.micro-type{
  padding-bottom: 22px;
  color: rgb(161, 160, 160);
  font-size: 18px;
  margin-bottom: 3rem;
}
.linkButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  margin: 10px 10px 0 0px;
  right: -18%;
}

.popup-frame {
  width: 30% !important;

  .continuebtn {
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    margin-top: 12px;

    button {
      background-color: #fc7711e9;
      color: white;
      padding: 7px 18px;
      border-radius: 5px;
      min-width: max-content;
      border: none;
      opacity: 1;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.link {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  position: absolute; 
  white-space: nowrap;
  top: -10.5rem;
  right:   -7%;
  top: -8.5rem;
  right:   0.5rem;
  @media(min-width:2000px){
   transform: scale(1.2);
  }
  &:hover {
    opacity: 0.8;

    .tooltip2 {
      visibility: visible;
    }
  }

  
  svg {
    width: 23px;
    height: 23px;


    g,
    path {
      fill: white;
    }
  }
}

// svg {
//     width: 15px;
//     height: 15px;

//     g,
//     path {
//         fill: white;
//     }
// } // THIS WAS BREAKING ALL SVGs

.cover-button {
    padding: 10px 15px;
    background-color: rgb(59, 59, 59);
    color: white;
    border-radius: 7px;
    background-color: rgb(45, 45, 45);
    width: 150px;
    &:hover {
        background-color: rgb(59, 59, 59);
        background-color: rgb(55, 55, 55);

    }
}

.skip-button {
  padding: 10px 15px;
  background-color: #fa7c1b;
  color: white;
  border-radius: 7px;
  margin: 0 7px;
  background-color: rgb(45, 45, 45);
  &:hover {
    background-color: rgb(59, 59, 59);
    background-color: rgb(55, 55, 55);
  }
}
.finish-select {
  min-width: 150px;
}

.circle {
  width: 14px;
  height: 14px;
  background-color: rgba(233, 233, 233, 0.407);
  border-radius: 50%;
  position: absolute;
  bottom: -27px;
  left: 0px;
  z-index: 99999;
  border: 2px solid transparent;

  &:hover {
    background-color: rgba(233, 233, 233, 0.407);
  }

  &.active {
    background-color: #ec6700!important;
  }
}

.bottom-btn {
  display: flex;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  padding-right: 9rem;
  margin-bottom: 4rem;
  flex-direction: column;
  position: fixed;
  bottom: 0rem;
  left: 2rem;
  z-index: 99999;
  // zoom: 0.8;
  button {
    margin: 5px 0;
    font-size: 13px;
    border-radius: 5px;
  }
  &.absolute {
    position: fixed;
    bottom: -1rem;
    left: 2rem;
}
}

.tooltip2 {
  position: absolute;
  right: 2.5rem;
  color: white;
  margin-top: 7px;
  font-size: 14px;
  visibility: hidden;
  opacity: 0.7;

  h2 {
    color: white;
  }
}

.bright-mode {
  .linkButton {
    svg {
      g,
      path {
        fill: rgb(0, 0, 0);
      }
    }
    #Rectangle_5157, #Rectangle_5158, #Rectangle_5159{
      fill: #6c757d;
    }
  }
  .settings-tooltip{
    color: black;
  }
  .tooltip2 {
    color: rgba(0, 0, 0, 0.709);
  }
  .search-container{
    svg{
      path{
        fill: rgb(0, 0, 0);
      }
    }
  }
  .pagination-wrapper{
    .pagination-list{
      .pagination-item.selected{
        color:black;
       }
    }
    .pagination-button{
      color: #212529;
    }
  }
}
.choosecover {
  margin-top: 2.3rem;
}

/* pagination */

.pagination-wrapper {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  .pagination-list {
    margin: 0 25px;
    padding: 0;
    display: flex;
    list-style-type: none;
    li {
      margin: 0 10px;
      cursor: pointer;
      &.selected {
        font-weight: bold;
      }
      &.unclickable-dots {
        cursor: default;
        color: rgba(201, 201, 201, 0.812);
      }
    }
    .pagination-item {
      color: gray;
      font-weight: 500;

      &.selected {
        color: white;
        font-weight: 500;
      }
    }
  }

    .pagination-button {
        color: rgb(201, 201, 201);

        &.unallowed {
            opacity: 0.4;
        }
    }
  }

.video-player {
  div {
    div {
      z-index: 0 !important;
    }
  }
  button {
    margin-left: 0 !important;
    margin-top: 20% !important;
    z-index: 1 !important;
  }
}

.links-thumnbail {
    width: 100%;
    position: relative;
    padding-top: 56.25%;
  .links-iframe {
    pointer-events: none;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.search-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6%;
  svg{
      width: 15px;
      g,path{
          fill: rgba(255, 255, 255, 0.524);
      }
  }
}
.search-div {
  width: 180px;
  margin-right: 10px;

    .search-bar {
        height: 35px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid gray;
        color: #CCCCCC;
        // text-align: right;
        padding: 0 10px;
        float: right;
        transition: 0.3s ease-in;


        &:focus {
            border: 2px solid #ec6700;
            color: #ec6700;

            &::placeholder {
                transition: 0.3s ease-in;
                color: #ec6700;
            }

        }
    }
}

.center-search-loader {
  height: 60vh;
}

.initials-top{
  text-transform: uppercase;
  position: fixed;
  z-index: 9;
  top: 13px;
  right:80px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: rgba(58, 58, 58, 0.639);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  color: white;
  z-index: 6;
  img{
    width: 46px;
    height: 46px;
    object-fit: cover;
    border-radius: 50%;
  }
}
.iconContainer {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #33333385;
  background-color: #333333e1;
  background-color: #333333bd;
  margin-top: 0.3rem;
  margin-right: 1rem;
  &:hover {
    background-color: #3333339f;
  }
  svg {
    transform: scale(0.8);
    g,
    path {
      fill: white;
    }
  }
}
.search-banner-content{
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #2C2C2C;
  border-radius: 30px;
  position: absolute;
  width: 25px;
  height: 25px;
  margin-top: -15px;
  right: 0rem;
  justify-content: center;
  top: 0;
  input{
    width: 100%;
    margin-left: -4px;
  }
*{
  font-size: 10px;
}
  svg{
    max-width: 10px;
      g,path{
        fill: gray;
      }
  }
}
.sharing-socials{
  margin-top: 4rem !important;
}
.sharevia-btn{
  position: absolute;
  color: #ec7600;
  bottom: 1.7rem;
  opacity: 0.7;
  font-weight: 600;
  border-radius: 5px;
  background-color: #ec7600;
  height: fit-content;
  right: 2.6rem;
  top: 8.8rem;
  width: 95px;
  font-size: 20px;
  &:hover{
    opacity: 1;
  }
}

@media(max-width:768px) {
  .sharevia-btn {
    right: 3.2rem;
    top: 6rem;
  }
}

@media(min-width:3500px) {
  .sharevia-btn {
    right: 4.9rem;
  }
}

@media(min-width:5000px) {
  .sharevia-btn {
    right: 5.5rem;
  }
}

.blue-mode,.purple-mode,.green-mode{
  .search-banner-content{
    svg path{
      fill: white;
    }
    input::placeholder{
      color:white;
    }
  }
  .initials-top{
    background-color: #0000002b;
  }
}
.blue-mode{
  .search-banner-content{
    background-color: #104971;
  }
}
.purple-mode{
  .search-banner-content{
    background-color: #2d3542;
  }
}
.green-mode{
  .search-banner-content{
    background-color: #144236;
  }
}
.bright-mode{
  .search-banner-content{
    background-color:#ededed;
  }
}
.default-case {
  width: 100%;
  display: flex;
  justify-content: center;
  .micrositedemoimage{
    max-width: 500px;
    margin: -10px 0 10px 0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.723);
    }
    .option2{
      margin-top: 5px;
    }
}

.microsite-cases {
  display: flex;
  gap: 20px;
  margin-left: -30px;
  margin-bottom: 4rem;
  margin-top: 2.5rem;
  width: 105%;
  .micrositedemoimage {
    max-width: 340px;
  }
}

.settings-tooltip {
  color: white;
  position: absolute;
  top: 7px;
  right: 38px;
  letter-spacing: 0.5px;
  transition: 0.25s all ease;
  transition-delay: 0.25s;
  font-size: 14px;
  padding: 2px;
  width: fit-content;
  opacity: 0.7;
  z-index: 2;
}

.accounts-data{
  width: 250px;
  position: fixed;
  top: 55px;
  right: 0;
  background-color: rgba(18, 18, 18, 0.696);
  z-index: 9999999;
  height: 100vh;
  backdrop-filter: blur(30px);
  background-color: #212121d5;
  .createbtn-acc{
    padding: 10px 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 17%;
    opacity: 0.7;
    &:hover{
      opacity: 1;
    }
    span{
      font-size: 30px;
      margin-right: 10px;
    }
  }
  button{
    color: white;
  }
  h2{
    font-size: 20px;
    padding: 10px 40px;
text-align: left;
    border-left: 3px solid #ec7600;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 0px;
    }
  h1{
    text-align: left;
    color: white;
    opacity: 0.6;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 18px;
    padding: 10px 40px;
    background-color: #17171745;
    margin: 0;
    padding: 10px 0 7px 25px;
    border-bottom: 0.5px solid #70707057;
    height: 50px;
    padding-left: 40px;
    padding-top: 12px;
    &.activeaccount{
      background:rgba(20, 20, 20, 0.986);
      opacity: 1;
    }
    &:hover{
      background:rgba(32, 32, 32, 0.503);
      opacity: 1;
    }
  }
  .accounts{
    max-height: 60vh;
    overflow: auto;
  }
}
.accountcreate-modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  .frame-account{
    padding: 4rem;
    background-color: gray;
    color: white;
    box-sizing: 2rem;
  }
}
@media screen and (min-width: 2000px) {
  h2{
    font-size: 22px; 
   }
  .search-container{
    svg{
      width: 20px;
    }
  }
  .pagination-wrapper{
    bottom:-30px;
    .pagination-list .pagination-item{
      font-size: 22px;
    }
    .pagination-list li {
      font-size: 22px;
    }
  }
  .options-container{
    margin-top: 2rem;
    p{
      font-size: 21px; 
      margin-top: 0px;
      margin-bottom: 15px;
    }
    .options-container-div{
      margin-top: 10px;
      margin-bottom: 10px;
    }
    h2{
      font-size: 21px;
    }
    .close-container{
      transform: scale(1.5);
    }
    label{
      font-size:20px;
    }
    input:checked:after{
      margin-left: 3.2px;
    }
  }
  .popup-frame .continuebtn button{
    padding: 10px 25px;
    font-size: 20px;
    width:max-content;
  }
  .bottom-btn button{
    font-size: 15px;
    letter-spacing: 1px;
    padding: 12px 20px;
  }
  .cancel-button,.finish-select, .cover-button{
    width: 200px;
  }
  .link{
    top: -7.5rem;
  }
  .initials-top{
    top: 10px;
    right: 86px;
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
  .circle{
    bottom: -35px;
  }
}
@media screen and (max-height: 650px) {
  .initials-top{
    top:7px;
    width: 32px;
    height: 32px;
    font-size: 15px;
  }
}
@media screen and (max-width: 1550px) {
  .search-banner-content{
    zoom: 0.9;
  }
}
@media screen and (min-width: 2560px) {
  .search-banner-content{
    input{
      font-size: 16px !important;
    }
  }
  .pagination-wrapper{
    .pagination-list .pagination-item{
      font-size: 28px;
    }
    .pagination-list li {
      font-size: 28px;
    }
  }
  .choose-coverpage{
    font-size: 20px;
  }
  .settings-tooltip{
    font-size: 17px;
  }
}
@media screen and (min-width: 3000px) {
  h2{
    font-size: 30px; 
   }
  .search-container{
    svg{
      width: 25px;
    }
  }
  .search-banner-content{
    input{
      font-size: 24px !important;
    }
  }
  .link{
    top: -5.5rem;
  }
  .pagination-wrapper{
    bottom:-50px;
    .pagination-list .pagination-item{
      font-size: 42px;
    }
    .pagination-button{
      transform: scale(1.5);
    }
    .pagination-list li {
      font-size: 42px;
    }
  }
  .link{
    transform: scale(1.8);
  }
  .options-container{
    p{
      font-size: 34px;
    }
    .close-container{
      transform: scale(2);
    }
    label{
      font-size:22px;
    }
    input[type=radio]{
      width: 34px;
      height: 34px;
      &::after{
        width: 22px;
        height: 22px;
        margin-top: 4.5px;
        margin-left: 4.5px;
      }
    }
  }
  .popup-frame .continuebtn button{
    font-size: 24px;
    padding: 10px 30px;
    letter-spacing: 1px;
  }
  .circle{
    width: 20px;
    height: 20px;
    bottom:-50px;
  }
  .bottom-btn button{
    font-size: 30px;
    padding: 14px 25px;
    width: 350px;
  }
  .initials-top{
    top: 6px;
    right: 111px;
    width: 64px;
    height: 64px;
    font-size: 28px;
  }
  .cancel-button,.finish-select{
    width: 350px;
  }
  .choose-coverpage{
    font-size: 33px;
    margin-top: -30px;
  }
  .settings-tooltip{
    font-size: 30px;
    top: 10px;
    right: 65px;
  }
}
@media screen and (min-width: 4000px) {
  h2{
   font-size: 40px; 
  }
  .search-container{
    svg{
      width: 40px;
    }
  }
  .link{
    transform: scale(2);
  }
  .pagination-wrapper{
    bottom:-150px;
    .pagination-list .pagination-item{
      font-size: 47px;
    }
    .pagination-button{
      transform: scale(2);
    }
  }
  .options-container{
    h2{
      font-size: 35px;
    }
    .close-container{
      transform: scale(2.5);
    }
  }
  .popup-frame .continuebtn button{
    font-size: 32px;
    padding: 10px 50px;
  }
  .circle{
    width:24px;
    height:24px;
    bottom: -55px;
  }
  .bottom-btn button{
    font-size: 40px;
    padding: 16px 30px;
    width: 430px;
  }
  .initials-top{
    top: 10px;
    right: 128px;
    width: 75px;
    height: 75px;
    font-size: 34px;
  }
}

.choose-coverpage{
  margin-bottom: 2rem;
}

.empty-list-link{
  margin-top: 10px;
  a{
    color: white;
    padding: 8px 30px;
    font-size: 16px;
    background-color: #ec7600;
    border-radius: 30px;
    &:hover{
    background-color: #fc8b19;
    }
  }
}
.search-menu{
  position: absolute;
  right: 0rem;
  padding: 2rem;
  z-index: 9999;
  background-color: #1d1d1d;
  border-radius: 10px;
  margin-top: 0.5rem;
  min-width: 15rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column ;
  color: rgba(255, 255, 255, 0.781);
  transition: .2s ease-in-out;
  .close-search-menu{
    position: absolute;
    top: 10px;
    right: 10px;
  color: rgba(255, 255, 255, 0.135);
  font-size: 25px;
  &:hover{
    color: rgba(255, 255, 255, 0.344);
  }
  }
  .search-input-bottom{
    display: flex;
  background-color: #292929;
  padding: 0px 10px;
  border-radius: 5px;
  margin-top: 20px;
    svg{
      width: 15px;
    }
  }
  .search-menu-options{
    display: flex;
    button{
      padding: 0 20px;
  color: rgba(255, 255, 255, 0.781);
  font-size: 14px;
  position: relative;
  margin: 0 5px;
  z-index: 1;
  cursor: pointer;
  &.active{
    &::after{
      background-color: #ec6700;
    }
  }
  &::after{
    content: "";
    position: absolute;
    border-radius: 50%;
    z-index: 1;
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.781);
    left: 0;
    margin-top: 4px;
    z-index: -1;
  }
    }
  }
  h2{
    font-size: 15px;
  }
}